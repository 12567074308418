<script setup lang="ts">
import { useI18n } from "vue-i18n";
import Trans from "~/modules/translations";

import AccountWidget from "../AccountWidget.vue";

import { useRouter } from "vue-router";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useStoreFlashSale } from "~/stores/storeFlashSale";
import { useStoreUserV2 } from "~/stores/storeUserV2";

const userStore = useStoreUserV2();
const storeFlashSale = useStoreFlashSale();
const { homeFlashSale, showFlashSaleBanner } = storeToRefs(storeFlashSale);

const { t, locale } = useI18n();
const router = useRouter();

function goToFlashSale() {
  if (homeFlashSale.value?.name) {
    router.push(
      Trans.i18nRoute({ name: "FlashSale", params: { name: homeFlashSale.value?.name } })
    );
  }
}

const navItems = [
  {
    title: "nav.play",
    name: "SelectCompetitionCategory",
  },
  {
    title: "nav.market",
    name: "Marketplace",
  },
  {
    title: "nav.myCards",
    name: "MyCards",
  },
  {
    title: "nav.referral",
    name: "Referral",
  },
  {
    title: "nav.data",
    name: "Data",
  },
];

const navItemsComputed = computed(() => {
  return navItems.filter((item) => {
    if (userStore.cuid) return item;
    else if (!["Referral", "MyCards"].includes(item.name)) return true;
  });
});
</script>

<template>
  <div class="p-6 py-4 w-full justify-between flex flex-row items-center uppercase">
    <div class="flex-row flex xl:gap-10 lg:gap-5 items-center">
      <router-link to="/"><icon-fg-logo /></router-link>
      <template class="hidden lg:flex flex-row">
        <div class="border-b-white/25 flex flex-row border-b-[1px] h-fit lg:gap-1 xl:gap-10">
          <router-link
            v-for="item in navItemsComputed"
            :key="item.name"
            :to="Trans.i18nRoute({ name: item.name })"
            class="nav-item tracking-wider xl:tracking-widest text-sm font-medium xl:font-semibold pb-1 px-2.5 border-b-[2px] border-transparent hover:border-[#EDFF00]"
          >
            {{ t(item.title) }}
          </router-link>

          <a
            :href="locale === 'fr' ? 'https://guide.fungiball.io' : 'https://guide.fungiball.io/en'"
            target="_blank"
            class="nav-item tracking-wider xl:tracking-widest text-sm font-medium xl:font-semibold pb-1 px-2.5 border-b-[2px] border-transparent hover:border-[#EDFF00]"
          >
            <template v-if="userStore.cuid">Guide</template>
            <template v-else>{{ t("guide.how-to-play") }}</template>
          </a>
        </div>
        <button
          v-if="showFlashSaleBanner"
          class="hidden d-xs:flex btn-secondary items-center px-f2 font-sans small-text font-semibold gap-f2 ml-2"
          @click="goToFlashSale"
        >
          <icon-fg-lightning />
          <span class="max-d-s:hidden">{{ $t("pages.marketplace.flashSale.isLive") }}</span>
          <span class="d-s:hidden">{{ $t("pages.marketplace.flashSale.isLiveShort") }}</span>

          <icon-ic:round-chevron-right class="bounce max-d-s:hidden" />
        </button>
      </template>
    </div>
    <AccountWidget />
  </div>
</template>

<style scoped>
.router-link-active {
  @apply border-[#EDFF00];
}

.text-shadow {
  text-shadow: 0 0 5px rgb(0, 0, 0, 1);
}
</style>

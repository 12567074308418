import { FungiballUnlockEquipment, NftEquipment } from "fungi-types";
import { Api } from "~/api/index";
import { IMetaData, IPagination } from "~/common/interfaces/IMarketplace";

export interface IEquipments {
  meta: IMetaData;
  data: Array<NftEquipment>;
}

export class EquipmentApi extends Api {
  constructor() {
    super(`equipements`);
  }

  public async getEquipments(
    filters: {
      tokenId?: number;
      gender?: string;
      court?: string;
    },
    pagination?: IPagination
  ): Promise<IEquipments> {
    const { data } = await this.api.get<IEquipments>(`/`, {
      params: {
        ...filters,
        page: pagination?.page,
        per_page: pagination?.per_page,
      },
    });
    return data as IEquipments;
  }

  public async getUnlockEquipments(): Promise<FungiballUnlockEquipment[]> {
    const { data } = await this.api.get<FungiballUnlockEquipment[]>(`/unlock-equipments`);
    return data;
  }
}

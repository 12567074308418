<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { UserEquipment } from "~/common/interfaces/IUser";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    nft: UserEquipment;
  }>(),
  {}
);
</script>

<template>
  <div class="flex w-full flex-col gap-2 cursor-default">
    <div class="w-full flex items-stretch justify-between text-black">
      <div class="bg-blue-500 rounded-md px-2 lg:text-sm">
        <p class="font-medium">Niv {{ nft.level }}</p>
      </div>
      <Tooltip :text="t('card.equipment.bonus')">
        <div
          class="bg-neutral-900 text-xs text-slate-200 rounded-md px-2 lg:text-sm grid place-content-center"
        >
          <p>+{{ nft.bonus }} %</p>
        </div>
      </Tooltip>
    </div>
    <div class="w-full flex items-stretch justify-between text-xs">
      <div class="text-slate-300 rounded-md">
        <p class="font-medium">{{ nft.brand }}</p>
      </div>

      <div class="text-slate-300 rounded-md">
        <p class="font-medium">{{ nft.edition }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

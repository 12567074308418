<script setup lang="ts">
import { useStoreUserV2 } from "@/stores/storeUserV2";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { Bell } from "lucide-vue-next";
import router from "@/router";

import Discord from "/icon-discord.svg?url";
import Twitter from "/icon-twitter.svg?url";

import Trans from "~/modules/translations";
import { useStoreApp } from "~/stores/storeApp";
import Button from "./formsV2/Button.vue";
import { useNotificationCenter } from "~/stores/storeNotificationCenter";

const { t } = useI18n();

const storeUser = useStoreUserV2();
const storeApp = useStoreApp();
const notificationCenter = useNotificationCenter();

const routeName = computed(() => (router.currentRoute.value.name as string) || "");

const handleShowDepositModal = () => {
  storeApp.showDepositModal = true;
};

const displaySignIn = computed<boolean>(() => routeName.value !== "Login");

const goToPage = (page: "SignUp" | "Login") => {
  router.push(Trans.i18nRoute({ name: page }));
};
</script>

<template>
  <div
    @click="storeUser.openProfileDrawer('default')"
    class="flex gap-1 rounded-full font-semibold"
    v-if="storeUser.isWalletConnected"
  >
    <div
      class="flex items-center bg-primary-110 bg-opacity-50 rounded-full border border-transparent hover:cursor-pointer hover:bg-opacity-100 transition-colors text-neutral-200 hover:text-white"
    >
      <Tooltip :text="t('drawers.profile.tokenBalance')">
        <div class="px-2 gap-2 flex items-center">
          <icon-fg-fungiball-token class="w-f3 h-f3" />

          <p class="font-normal">
            {{ storeUser.tokenBalance }}
          </p>
        </div>
      </Tooltip>

      <span class="text-neutral-700"> | </span>

      <Tooltip :text="t('drawers.profile.wpolBalance')">
        <div @click.stop="handleShowDepositModal" class="px-2 gap-2 flex items-center rounded-full">
          <icon-fgc-w-matic class="w-f3 h-f3" />

          <p class="font-normal">
            {{ storeUser.availableWMaticBalance.toFixed(2) }}
          </p>
        </div>
      </Tooltip>

      <span class="text-neutral-700"> | </span>

      <Tooltip
        :text="
          t('drawers.notifications.notificationCount', {
            count: notificationCenter.unreadNotificationsCount,
          })
        "
      >
        <div
          @click.stop="notificationCenter.open = true"
          class="px-2 gap-2 flex items-center rounded-full"
        >
          <div class="relative">
            <Bell class="w-f3 h-f3" />

            <div
              v-if="notificationCenter.unreadNotificationsCount > 0"
              class="absolute -top-2 -right-2 w-4 h-4 bg-red-600 flex items-center justify-center rounded-full text-[10px]"
            >
              {{ notificationCenter.unreadNotificationsCount }}
            </div>
          </div>
        </div>
      </Tooltip>

      <span class="text-neutral-700"> | </span>

      <button tabindex="-1" class="flex items-center gap-2.5">
        <p class="overflow-hidden whitespace-nowrap text-ellipsis max-w-28 ml-3">
          {{ storeUser.username }}
        </p>
        <img src="/images/avatar.png" alt="avatar" class="w-8 h-8 rounded-full" />
      </button>
    </div>
  </div>

  <div v-else-if="displaySignIn" class="flex gap-4 items-center">
    <div class="flex flex-row gap-2">
      <a
        href="https://x.com/Fungiball_off"
        target="_blank"
        class="p-2 border flex px-2.5 items-center border-neutral-200 rounded-full hover:cursor-pointer hover:border-white transition-colors"
      >
        <img :src="Twitter" alt="Twitter" class="h-4" />
      </a>

      <a
        href="https://discord.gg/YJJw7RP33K"
        target="_blank"
        class="p-2 border flex items-center border-neutral-200 rounded-full hover:cursor-pointer hover:border-white transition-colors"
      >
        <img :src="Discord" alt="Discord" class="h-5" />
      </a>
    </div>

    <Button @click="goToPage('SignUp')" size="sm" class="hidden sm:block" type="primary-outline">
      {{ $t("nav.signup") }}
    </Button>

    <Button @click="goToPage('Login')" size="sm" class="hidden sm:block" type="primary-white">
      {{ $t("nav.login") }}
    </Button>
  </div>
</template>

<style scoped></style>

<script setup lang="ts">
import { computed } from "vue";

const emits = defineEmits(["click"]);

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    loading?: boolean;
    type?:
      | "primary-blue"
      | "primary-black"
      | "primary-white"
      | "primary-outline"
      | "secondary"
      | "secondary-outline";
    size?: "xs" | "sm" | "md" | "lg";
  }>(),
  {
    loading: false,
    disabled: false,
    size: "md",
  }
);

const fontSize = computed(() => {
  switch (props.size) {
    case "xs":
      return "text-xs py-2 px-5";
    case "sm":
      return "text-sm py-2 px-5";
    case "md":
      return "text-md py-2 px-5";
    case "lg":
      return "text-lg py-2 px-5";
    default:
      return "text-xs py-2 px-5";
  }
});

const loadingClass = computed(() => {
  if (!props.loading) return "";
  switch (props.type) {
    case "primary-blue":
      return "bg-blue-950 text-neutral-400 hover:bg-blue-950";
    case "primary-white":
      return "";
    case "primary-black":
      return "";
    default:
      return "";
  }
});

const type = computed(() => {
  switch (props.type) {
    case "primary-blue":
      return "bg-blue-800 text-neutral-200 border-blue-700 hover:bg-blue-700 primary-blue-disabled";
    case "primary-white":
      return "bg-neutral-200 border-neutral-200 text-neutral-950 hover:bg-white hover:text-black border-blue-600";
    case "primary-black":
      return "bg-zinc-950 text-neutral-200 !border-neutral-200 hover:border-white hover:text-black hover:bg-white";
    case "primary-outline":
      return "bg-transparent text-neutral-200 border border-neutral-200 hover:border-white hover:text-black hover:bg-white";
    case "secondary":
      return "bg-zinc-900 text-neutral-200 text-neutral-200 border-neutral-800 hover:border-neutral-500 hover:text-white hover:bg-zinc-900 hover:text-white";
    case "secondary-outline":
      return "text-neutral-200 text-neutral-200 border-neutral-800 hover:border-neutral-500 hover:text-white hover:bg-zinc-900 hover:text-white";
    default:
      return "";
  }
});
</script>

<template>
  <button
    @click.stop="emits('click')"
    class="focus:outline-none rounded-full tracking-wide border disabled:bg-neutral-900 disabled:text-neutral-400 disabled:border-neutral-600 transition-colors ease-in duration-100"
    :class="[fontSize, loadingClass, type]"
    :disabled="props.disabled"
  >
    <slot />
  </button>
</template>

<style scoped>
.primary-blue-disabled {
  @apply disabled:bg-blue-900 disabled:text-neutral-200/50 disabled:border-blue-900;
}
</style>

<script setup lang="ts">
import { ref, computed, toRefs, watch, onMounted, onUnmounted, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { ethers } from "ethers";
import { debounce } from "lodash";

import Modal from "@/components/modals/ModalContainer.vue";
import { useStoreApp } from "~/stores/storeApp";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { DisplayCurrencyMode } from "~/application/enums/DisplayCurrencyMode.enum";
import { createToast } from "mosha-vue-toastify";
import {
  chainConfig,
  etherscanUrl,
  maticOnEthereumChainContractAddress,
} from "~/application/config";
import { maticABIEth } from "~/application/abi/maticABIEth";

const { t } = useI18n();
const storeApp = useStoreApp();
const storeUser = useStoreUserV2();
const recipientAddress = ref("");
const {
  walletAddress: senderAddress,
  currentEthereumBalance: ethBalance,
  currentMaticOnEthBalance: maticOnEthBalance,
} = toRefs(storeUser);
const lastTransactionHash = ref("");

const amount = ref(0);
const activeTab = ref<"eth" | "maticOnEth">("eth");
const gasFee = ref(0);

const showTransferModal = () => {
  storeApp.showTransferModal = true;
  storeApp.showWithdrawModal = false;
};

const netBalance = computed((): number => {
  return Math.max(
    0,
    activeTab.value === "eth"
      ? ethBalance.value - gasFee.value
      : maticOnEthBalance.value - gasFee.value
  );
});

const emit = defineEmits(["onClose"]);
withDefaults(
  defineProps<{
    show: boolean;
  }>(),
  {
    show: false,
  }
);

onMounted(async () => {
  await gasEstimation();
  storeUser.refreshBalance();
});

const etherScanUrl = computed((): string => {
  return `${etherscanUrl}tx/${lastTransactionHash.value}`;
});

const handleRecipientAddressChange = (name: string, value: string) => {
  recipientAddress.value = value;
};

const isValidWalletAddress = computed((): boolean => {
  return ethers.utils.isAddress(recipientAddress.value);
});

async function withdrawEther() {
  if (!isValidWalletAddress.value || amount.value <= 0) {
    createToast(t("messages.invalid-wallet-address"), {
      type: "danger",
    });
    return;
  }

  if (amount.value < 0 || amount.value > netBalance.value) {
    createToast(t("messages.invalid-amount"), {
      type: "danger",
    });
    return;
  }

  try {
    storeApp.setActionInProgress(true);

    if (activeTab.value === "eth") {
      lastTransactionHash.value = await storeUser.wallet.sendETH(
        recipientAddress.value,
        amount.value.toString()
      );
    } else {
      lastTransactionHash.value = await storeUser.wallet.sendMaticOnEthereumChain(
        recipientAddress.value,
        amount.value.toString()
      );
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await storeUser.refreshBalance();
  } catch (error: any) {
    createToast(
      t("messages.transaction-transfer-failed", {
        n: amount.value.toFixed(8),
        a: recipientAddress.value,
      }),
      {
        type: "danger",
      }
    );
  } finally {
    storeApp.setActionInProgress(false);
  }
}

const handleOnClose = () => {
  resetValues();
  emit("onClose");
};

const resetValues = () => {
  recipientAddress.value = "";
  lastTransactionHash.value = "";
  amount.value = 0;
  gasFee.value = 0;
};

const debouncedGasEstimation = debounce(gasEstimation, 1000);

onUnmounted(() => {
  debouncedGasEstimation.cancel();
  storeUser.wallet.web3auth.switchChain({ chainId: chainConfig.chainId });
});

async function gasEstimation() {
  if (amount.value <= 0) return;

  try {
    await storeUser.wallet.addAndSwitchChain();
    const updatedProvider = new ethers.providers.Web3Provider(
      storeUser.wallet.web3auth.provider as any
    );

    if (activeTab.value === "eth") {
      const gasPrice = await updatedProvider.getGasPrice();
      const gasLimit = await updatedProvider.estimateGas({
        to: recipientAddress.value,
        value: ethers.utils.parseEther(amount.value.toString()),
      });

      gasFee.value = parseFloat(ethers.utils.formatEther(gasPrice.mul(gasLimit)));
    } else {
      const contract = new ethers.Contract(
        maticOnEthereumChainContractAddress,
        maticABIEth,
        updatedProvider
      );

      const gasPrice = await updatedProvider.getGasPrice();
      const gasLimit = await contract.estimateGas.transfer(
        recipientAddress.value,
        ethers.utils.parseEther(amount.value.toString())
      );

      gasFee.value = parseFloat(ethers.utils.formatEther(gasPrice.mul(gasLimit)));
    }
  } catch (error) {
    gasFee.value = 0;
  } finally {
    storeUser.wallet.web3auth.switchChain({ chainId: chainConfig.chainId });
  }
}

watch([amount, recipientAddress, activeTab], () => {
  debouncedGasEstimation();
});

const setTab = (tab: "eth" | "maticOnEth") => {
  activeTab.value = tab;
};
</script>

<template>
  <Modal
    :show="show"
    @onClose="handleOnClose"
    :title="
      lastTransactionHash.length === 0
        ? t('modals.withdraw.ethfromwallettoanother')
        : t('modals.withdraw.transactionDone')
    "
  >
    <div v-if="lastTransactionHash.length === 0" class="flex flex-col gap-4">
      <p class="whitespace-pre-line text-greyLight text-sm text-justify">
        {{ t("modals.withdraw.withdrawDesc") }}
      </p>
      <div class="bg-zinc-800 p-1 rounded-full flex flex-row w-full gap-2 justify-between">
        <div
          v-for="tab in ['eth', 'maticOnEth']"
          @click="setTab(tab as 'eth' | 'maticOnEth')"
          :key="tab"
          class="text-center rounded-full py-1 cursor-pointer text-sm px-4 w-full hover:bg-black transition-colors duration-200"
          :class="{ 'bg-black': activeTab === tab }"
        >
          {{ t(`modals.withdraw.${tab}`) }}
        </div>
      </div>
      <div class="flex flex-row items-center justify-center gap-5 w-full">
        <div class="flex flex-col items-start justify-center w-full">
          <div class="flex flex-row md:flex-row justify-between w-full mt-5 text-sm">
            <span class="font-bold uppercase">{{ t("modals.transfer.amountToTransfer") }}</span>
            <span v-if="activeTab === 'eth'" class="uppercase"
              >{{ t("modals.transfer.balance") }} {{ ethBalance }}</span
            >
            <span v-else class="uppercase"
              >{{ t("modals.transfer.balance") }} {{ maticOnEthBalance }}</span
            >
          </div>
          <div class="bg-slate-800 rounded-md w-full flex flex-row gap-2 items-center">
            <CurrencyInput
              currency=""
              :key="activeTab === 'eth' ? ethBalance : maticOnEthBalance"
              v-model="amount"
              currency-mode="hidden"
              class="w-full"
              :mode="activeTab === 'eth' ? DisplayCurrencyMode.ETH : DisplayCurrencyMode.MTC"
              :min="0"
              :max="Math.max(0, netBalance)"
              :disabled="storeApp.actionInProgress"
            />
          </div>
          <div class="flex flex-row justify-between w-full mt-5 text-sm">
            <span class="font-bold uppercase">{{ t("modals.withdraw.senderWallet") }}</span>
          </div>
          <div class="bg-slate-800 rounded-md w-full flex flex-row p-1 px-2 items-center">
            <icon-fgc-ethereum class="w-8 h-8" />
            <FormTextInput
              class="w-full p-0 !bg-transparent"
              inputClass="!bg-transparent"
              :placeholder="t('modals.transfer.wallet-address')"
              name="walletAddress"
              type="text"
              :disabled="true"
              :value="senderAddress"
            />
          </div>
          <div class="flex flex-row justify-between w-full mt-5 text-sm">
            <span class="font-bold uppercase">{{ t("modals.transfer.targetWallet") }}</span>
          </div>
          <div class="bg-slate-800 rounded-md w-full flex flex-row p-1 px-2 items-center">
            <icon-fgc-ethereum class="w-8 h-8" />
            <FormTextInput
              class="w-full p-0 !bg-transparent"
              inputClass="!bg-transparent"
              :placeholder="t('modals.transfer.wallet-address')"
              name="walletAddress"
              type="text"
              :valid="isValidWalletAddress"
              :value="recipientAddress"
              :disabled="storeApp.actionInProgress"
              @change="handleRecipientAddressChange"
            />
          </div>
        </div>
      </div>

      <ButtonPrimary :blue="true" :disabled="storeApp.actionInProgress" @click="withdrawEther">
        {{
          !storeApp.actionInProgress ? t("modals.withdraw.confirm") : t("modals.withdraw.loading")
        }}
      </ButtonPrimary>
      <span class="text-center w-full"
        >{{ t("modals.transfer.estGas") }}
        {{ gasFee.toLocaleString("fullwide", { maximumSignificantDigits: 10 }) }}</span
      >
      <div class="text-center w-full">
        <a
          @click="showTransferModal"
          class="text-xs text-gray-400 cursor-pointer hover:underline hover:text-white"
          >{{ t("modals.transfer.switch-to-pol-wallet") }}</a
        >
      </div>
    </div>
    <div v-else class="flex flex-col gap-4">
      <div class="flex flex-col items-center justify-center w-full gap-2">
        <a v-if="lastTransactionHash" class="btn-link" :href="etherScanUrl" target="_blank"
          >{{ t("links.transaction-etherscan") }}
          <icon-mi:external-link class="inline" />
        </a>
      </div>
      <ButtonPrimary :blue="false" @click="handleOnClose">
        {{ t("modals.withdraw.close") }}
      </ButtonPrimary>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/modals/ModalContainer.vue";
import { computed, ref } from "vue";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { useStoreApp } from "~/stores/storeApp";
import { useUpgradeEquipment } from "~/composables/useUpgradeEquipment";
import Loader from "~/components/dashboard/Loader.vue";
import ButtonPrimary from "~/components/ButtonPrimary.vue";
import { useI18n } from "vue-i18n";
import { useStoreEquipmentDeck } from "~/stores/storeEquipmentDeck";
import { TokenPrice } from "fungi-types";

withDefaults(
  defineProps<{
    show: boolean;
  }>(),
  {
    show: false,
  }
);

const storeUser = useStoreUserV2();
const { selectedEquipment, clearSelectedEquipment, unlockEquipment } = useUpgradeEquipment();
const isLoading = ref(false);
const { t, locale } = useI18n();

const isCurrencyFirst = computed(() => {
  return locale.value === "en";
});

const handleOnClose = () => {
  useStoreApp().showUnlockEquipmentModal = false;
  clearSelectedEquipment();
};

const unlock = async () => {
  isLoading.value = true;
  const newEquipment = await unlockEquipment(storeUser.wallet.currentAccount);
  if (!newEquipment) {
    return;
  }
  await storeUser.decreaseFungiballTokens(TokenPrice.UNLOCK);
  await useStoreEquipmentDeck().getUserEquipment(storeUser.wallet.currentAccount);
  await useStoreEquipmentDeck().getUnlockEquipment();
  isLoading.value = false;
  handleOnClose();
};
</script>

<template>
  <Modal
    v-if="selectedEquipment"
    :show="show"
    @onClose="handleOnClose"
    :bgColor="'bg-neutral-900'"
    :title="selectedEquipment.title"
  >
    <div class="relative flex flex-col lg:gap-3 lg:flex-col gap-3 justify-around">
      <p class="whitespace-pre-line text-greyLight text-sm text-center">
        {{ t("modals.equipmentUnlock.description") }}
      </p>
      <Loader
        v-if="isLoading"
        class="bg-slate-950/50 backdrop-blur-md z-10 flex items-center justify-center"
      />

      <div class="w-full items-center justify-center flex">
        <NftSkill class="max-w-52 lg:max-w-96" :nft="selectedEquipment" :background="false">
          <template #footer-data>
            <CardDataFooterSkeleton>
              <template #header>
                <CardDataFooterEquipment :nft="selectedEquipment" />
              </template>
            </CardDataFooterSkeleton>
          </template>
        </NftSkill>
      </div>
      <div class="flex flex-row gap-4">
        <ButtonPrimary @click="handleOnClose" class="w-full">
          {{ t("modals.equipmentUnlock.cancel") }}
        </ButtonPrimary>
        <ButtonPrimary class="w-full" @click="unlock">
          <div class="flex items-center gap-2 justify-center">
            <span>{{ t("modals.equipmentUnlock.confirm") }}</span>
            <template v-if="isCurrencyFirst">
              <icon-fg-fungiball-token class="w-f3 h-f3" />
              <span class="text-base">{{ TokenPrice.UNLOCK }}</span>
            </template>
            <template v-else>
              <span class="text-base">{{ TokenPrice.UNLOCK }}</span>
              <icon-fg-fungiball-token class="w-f3 h-f3" />
            </template>
          </div>
        </ButtonPrimary>
      </div>
    </div>
  </Modal>
</template>

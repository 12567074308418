<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components";
import { X } from "lucide-vue-next";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const emit = defineEmits(["onClose", "onGoBack"]);
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    title?: string;
    show: boolean;
    headerIMG?: string;
    size?: "sm" | "md" | "lg" | "full" | "4xl" | "5xl" | "6xl" | number;
    bgColor?: string;
    modalClasses?: string;
    closeButtonClasses?: string;
    showCloseButton?: boolean;
    showGoBackButton?: boolean;
  }>(),
  {
    showGoBackButton: false,
    showCloseButton: true,
  }
);

const bgColor = props.bgColor || "bg-gray-950";
const padding = "p-5";

const handleOnClose = () => emit("onClose");
const handleOnGoBack = () => emit("onGoBack");

const computedSize = computed(() => {
  const defaultSize = "max-w-lg";
  if (!props.size) return defaultSize;
  else if (typeof props.size === "number") return `max-w-[${props.size}px]`;
  else return `max-w-${props.size}`;
});
</script>

<template>
  <teleport to="body">
    <transition>
      <div v-show="show" class="fixed z-50 w-full overflow-y-auto flex justify-center p-2 inset-0">
        <div class="fixed inset-0 bg-slate-400/30 backdrop-blur-sm"></div>
        <transition
          enterActiveClass="transition ease-out duration-200 transform delay-100"
          enterFromClass="opacity-0"
          enterToClass="opacity-100"
          leaveActiveClass="ease-in duration-200"
          leaveFromClass="opacity-100"
          leaveToClass="opacity-0"
        >
          <div
            v-show="show"
            @click.self="handleOnClose"
            v-on-click-outside="handleOnClose"
            class="flex flex-col items-stretch w-full z-0 justify-center relative"
            :class="computedSize + ' ' + modalClasses"
          >
            <div
              :class="`${bgColor} ${headerIMG ? 'p-1' : padding} ${$slots.footer ? '' : 'sm:rounded-b-xl'} overflow-auto h-fit rounded-t-xl relative pb-5`"
            >
              <button
                v-if="showCloseButton"
                @click="handleOnClose"
                class="p-2 absolute top-4 right-4 rounded-full hover:bg-slate-800 bg-black border-[1px] border-slate-600 hidden sm:block"
                :class="closeButtonClasses"
              >
                <X class="h-5 w-5" />
              </button>

              <button
                v-if="showGoBackButton"
                @click="handleOnGoBack"
                class="p-2 absolute top-4 left-4 rounded-full hover:bg-slate-800 bg-black border-[1px] border-slate-600 hidden sm:block text-sm px-4"
                :class="closeButtonClasses"
              >
                <div class="flex flex-row gap-2 items-center">
                  <icon-fg-back-arrow class="h-4 w-4" /> {{ t("back") }}
                </div>
              </button>
              <div
                v-if="headerIMG"
                :style="{ backgroundImage: 'url(' + headerIMG + ')' }"
                class="bg-cover bg-center h-40 top-2 left-2 right-2 rounded-t-xl"
              ></div>
              <div
                v-if="title"
                class="text-center text-xl md:text-2xl font-semibold p-5 sm:px-10 sm:pt-10 mb-5"
              >
                {{ title }}
              </div>
              <slot></slot>
            </div>
            <div
              :class="`border-slate-800 border-t-[1px] ${$slots.footer ? '' : 'sm:border-none'}`"
            >
              <div v-if="$slots.footer" :class="`${bgColor} p-2 sm:rounded-b-xl`">
                <slot name="footer"> </slot>
              </div>
              <div
                v-if="showCloseButton || showGoBackButton"
                :class="`w-full  sm:hidden text-sm  text-center ${bgColor} rounded-b-xl flex w-full justify-between divide-x-2 divide-zinc-800`"
              >
                <button
                  v-if="showGoBackButton"
                  @click="handleOnGoBack"
                  class="hover:bg-slate-800 w-full p-4"
                >
                  {{ t("back") }}
                </button>
                <button
                  v-if="showCloseButton"
                  @click="handleOnClose"
                  class="hover:bg-slate-800 w-full p-4"
                >
                  {{ t("button.close") }}
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </teleport>
</template>

<style scoped>
.body-table {
  border-collapse: separate;
  border-spacing: 0px;
}
.body-table .bg-body td {
  border-spacing: 0px 2px;
  border-color: #333;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
}

.body-table th:last-child {
  text-align: end;
}

.body-table tr.bg-body td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right-width: 1px;
}

.body-table tr.bg-body td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left-width: 1px;
}

.bg-body {
  background-color: #1f272b;
}
</style>

<script setup lang="ts">
import NftSkill from "../marketplace/NftSkill.vue";
import { UserEquipment } from "~/common/interfaces/IUser";
import CardDataFooterSkeleton from "../card/CardDataFooterSkeleton.vue";
import CardDataFooterEquipment from "../card/CardDataFooterEquipment.vue";
import { computed, watchEffect } from "vue";
import { useStoreApp } from "~/stores/storeApp";
import { TokenPrice } from "fungi-types";
import ButtonPrimary from "../ButtonPrimary.vue";
import { useI18n } from "vue-i18n";

const props = withDefaults(
  defineProps<{
    item: UserEquipment;
    loading?: boolean;
    isUpgradable?: boolean;
    canUnlock?: boolean;
    tooltipMessage?: string;
    toUnlock?: boolean;
    isMax?: boolean;
  }>(),
  {
    toUnlock: false,
    isMax: false,
  }
);

const { locale } = useI18n();

const emits = defineEmits<{
  (event: "showUpgradeModal", item: UserEquipment): void;
  (event: "showUnlockModal", item: UserEquipment): void;
}>();

const handleButtonClick = () => {
  useStoreApp().showEquipmentsUpgradeModal = false;
  useStoreApp().showUnlockEquipmentModal = false;
  if (props.isUpgradable) {
    emits("showUpgradeModal", props.item);
  } else if (props.toUnlock && props.canUnlock) {
    emits("showUnlockModal", props.item);
  }
};

const isCurrencyFirst = computed(() => {
  return locale.value === "en";
});

const isButtonDisabled = computed(() => {
  if (props.toUnlock) {
    return !props.canUnlock;
  }
  return !props.isUpgradable;
});
</script>

<template>
  <NftSkill :nft="item">
    <template #footer-data>
      <CardDataFooterSkeleton>
        <template #header>
          <CardDataFooterEquipment :nft="item" />
        </template>
      </CardDataFooterSkeleton>
    </template>
    <template #cta>
      <div class="relative w-full">
        <Tooltip :text="tooltipMessage">
          <ButtonPrimary class="w-full" @click="handleButtonClick" :disabled="isButtonDisabled">
            <span
              v-if="isMax"
              class="font-sans font-medium flex flex-row place-content-center items-center gap-2"
            >
              {{ $t("pages.myGears.max") }}
            </span>
            <span
              v-else-if="toUnlock"
              class="font-sans font-medium flex flex-row place-content-center items-center gap-2"
            >
              <template v-if="isCurrencyFirst">
                <icon-fg-fungiball-token class="w-f3 h-f3" />
                {{ TokenPrice.UNLOCK }}
              </template>
              <template v-else>
                {{ TokenPrice.UNLOCK }}
                <icon-fg-fungiball-token class="w-f3 h-f3" />
              </template>
            </span>
            <span
              v-else
              class="font-sans font-medium flex flex-row place-content-center items-center gap-2"
            >
              {{ $t("pages.myGears.craft") }}
              <template v-if="isCurrencyFirst">
                <icon-fg-fungiball-token class="w-f3 h-f3" />
                {{ TokenPrice.UPGRADE }}
              </template>
              <template v-else>
                {{ TokenPrice.UPGRADE }}
                <icon-fg-fungiball-token class="w-f3 h-f3" />
              </template>
            </span>
          </ButtonPrimary>
        </Tooltip>
      </div>
    </template>
  </NftSkill>
</template>

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquipmentsEdition = exports.EquipmentsLevel = exports.TokenPrice = exports.Brand = exports.Tiers = exports.RewardType = exports.RegistrationStatus = exports.Surface = exports.MatchType = exports.PriceCurrency = exports.MatchStatus = exports.ValueType = exports.GameweekGender = exports.Scarcity = exports.GameweekType = exports.GameweekStatus = void 0;
var GameweekStatus;
(function (GameweekStatus) {
    GameweekStatus["Closed"] = "closed";
    GameweekStatus["Canceled"] = "canceled";
    GameweekStatus["Ongoing"] = "ongoing";
    GameweekStatus["Upcoming"] = "upcoming";
    GameweekStatus["Ready"] = "ready";
})(GameweekStatus || (exports.GameweekStatus = GameweekStatus = {}));
var GameweekType;
(function (GameweekType) {
    GameweekType["F2P"] = "f2p";
    GameweekType["P2P"] = "p2p";
})(GameweekType || (exports.GameweekType = GameweekType = {}));
var Scarcity;
(function (Scarcity) {
    Scarcity["SILVER"] = "silver";
    Scarcity["GOLD"] = "gold";
    Scarcity["PLATINUM"] = "platinum";
})(Scarcity || (exports.Scarcity = Scarcity = {}));
var GameweekGender;
(function (GameweekGender) {
    GameweekGender["MALE"] = "Male";
    GameweekGender["FEMALE"] = "Female";
    GameweekGender["MIXED"] = "mixed";
})(GameweekGender || (exports.GameweekGender = GameweekGender = {}));
var ValueType;
(function (ValueType) {
    ValueType["PERCENTAGE"] = "percentage";
    ValueType["NUMBER"] = "number";
    ValueType["BOOLEAN"] = "boolean";
    ValueType["STRING"] = "string";
})(ValueType || (exports.ValueType = ValueType = {}));
var MatchStatus;
(function (MatchStatus) {
    MatchStatus["NotStarted"] = "not_started";
    MatchStatus["aboutToStart"] = "match_about_to_start";
    MatchStatus["Ended"] = "ended";
    MatchStatus["Interrupted"] = "interrupted";
    MatchStatus["Defaulted"] = "defaulted";
    MatchStatus["Postponed"] = "postponed";
    MatchStatus["Cancelled"] = "cancelled";
    MatchStatus["Walkover"] = "walkover";
    MatchStatus["FirstSet"] = "1st_set";
    MatchStatus["SecondSet"] = "2nd_set";
    MatchStatus["ThirdSet"] = "3rd_set";
    MatchStatus["FourthSet"] = "4th_set";
    MatchStatus["FifthSet"] = "5th_set";
    MatchStatus["Retired"] = "retired";
    MatchStatus["StartDelayed"] = "start_delayed";
    MatchStatus["Suspended"] = "suspended";
    MatchStatus["Started"] = "started";
    MatchStatus["Abandoned"] = "abandoned";
    MatchStatus["Unknown"] = "unknown";
})(MatchStatus || (exports.MatchStatus = MatchStatus = {}));
var PriceCurrency;
(function (PriceCurrency) {
    PriceCurrency["$"] = "$";
})(PriceCurrency || (exports.PriceCurrency = PriceCurrency = {}));
var MatchType;
(function (MatchType) {
    MatchType["Singles"] = "singles";
    MatchType["Doubles"] = "doubles";
    MatchType["Mixed"] = "mixed";
})(MatchType || (exports.MatchType = MatchType = {}));
var Surface;
(function (Surface) {
    Surface["HardIndoor"] = "hard_outdoor";
    Surface["HardOutdoor"] = "hard_indoor";
    Surface["ClayIndoor"] = "clay_outdoor";
    Surface["ClayOutdoor"] = "clay_indoor";
    Surface["GrassIndoor"] = "grass_outdoor";
    Surface["GrassOutdoor"] = "grass_indoor";
})(Surface || (exports.Surface = Surface = {}));
var RegistrationStatus;
(function (RegistrationStatus) {
    RegistrationStatus["Draft"] = "draft";
    RegistrationStatus["Submitted"] = "submitted";
})(RegistrationStatus || (exports.RegistrationStatus = RegistrationStatus = {}));
var RewardType;
(function (RewardType) {
    RewardType["SKILL"] = "skill";
    RewardType["EQUIPMENT"] = "equipment";
    RewardType["USD"] = "usd";
    RewardType["TOKEN"] = "token";
})(RewardType || (exports.RewardType = RewardType = {}));
var Tiers;
(function (Tiers) {
    Tiers["S"] = "S";
    Tiers["A"] = "A";
    Tiers["B"] = "B";
    Tiers["C"] = "C";
    Tiers["D"] = "D";
})(Tiers || (exports.Tiers = Tiers = {}));
var Brand;
(function (Brand) {
    Brand["FUNGIBALL"] = "Fungiball";
    Brand["LECOQSPORTIF"] = "Le coq sportif";
})(Brand || (exports.Brand = Brand = {}));
var TokenPrice;
(function (TokenPrice) {
    TokenPrice[TokenPrice["UNLOCK"] = 100] = "UNLOCK";
    TokenPrice[TokenPrice["UPGRADE"] = 2500] = "UPGRADE";
})(TokenPrice || (exports.TokenPrice = TokenPrice = {}));
var EquipmentsLevel;
(function (EquipmentsLevel) {
    EquipmentsLevel[EquipmentsLevel["MIN"] = 1] = "MIN";
    EquipmentsLevel[EquipmentsLevel["MAX"] = 5] = "MAX";
})(EquipmentsLevel || (exports.EquipmentsLevel = EquipmentsLevel = {}));
var EquipmentsEdition;
(function (EquipmentsEdition) {
    EquipmentsEdition["FIRSTEDITION2023"] = "First Edition 2023";
    EquipmentsEdition["CHRISTMASEDITION2024"] = "Christmas Edition 2024";
})(EquipmentsEdition || (exports.EquipmentsEdition = EquipmentsEdition = {}));

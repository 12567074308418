<script setup lang="ts">
import { ref } from "vue";
import { computePosition, flip, shift, offset, arrow, Placement } from "@floating-ui/dom";

const reference = ref<HTMLElement>();
const floating = ref<HTMLElement>();
const floatingArrow = ref<HTMLElement>();
const isHidden = ref(true);

const props = withDefaults(
  defineProps<{
    placement?: Placement;
    theme?: "dark" | "light";
    text?: string;
    offset?: number;
    maxWidth?: number;
    hideOnClick?: boolean;
  }>(),
  {
    content: "",
    position: "bottom",
    theme: "light",
    offset: 8,
    maxWidth: 200,
    hideOnClick: false,
  }
);

const updatePosition = async () => {
  if (reference.value && floating.value && floatingArrow.value) {
    const { x, y, middlewareData, placement } = await computePosition(
      reference.value,
      floating.value,
      {
        placement: props.placement,
        middleware: [
          offset(props.offset),
          flip(),
          shift({ padding: 5 }),
          arrow({ element: floatingArrow.value }),
        ],
      }
    );

    // Get the current scroll position
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Apply position taking into account the scroll offset
    Object.assign(floating.value.style, {
      left: `${x + scrollX}px`,
      top: `${y}px`,
    });

    let arrowX, arrowY;

    if (middlewareData.arrow) {
      ({ x: arrowX, y: arrowY } = middlewareData.arrow);
    }

    const opposedSide: any = {
      left: "right",
      right: "left",
      bottom: "top",
      top: "bottom",
    }[placement.split("-")[0]];

    Object.assign(floatingArrow.value.style, {
      left: arrowX ? `${arrowX}px` : "",
      top: arrowY ? `${arrowY}px` : "",
      bottom: "",
      right: "",
      [opposedSide]: "-4px",
    });
  }
};

const hide = () => {
  isHidden.value = true;
};

const show = () => {
  isHidden.value = false;
  updatePosition();
};

const handleClick = () => {
  if (props.hideOnClick) hide();
};
</script>

<template>
  <div
    ref="reference"
    @blur="hide"
    @focus="show"
    @mouseenter="show"
    @mouseleave="hide"
    @click="handleClick"
  >
    <slot />
  </div>
  <Teleport to="body">
    <div
      ref="floating"
      :class="[
        'absolute top-0 left-0 z-[9999]',
        'text-xs px-3 py-1.5 rounded-md cursor-default',
        isHidden && 'hidden',
        `max-w-96`,
        theme === 'dark' ? 'bg-slate-900 text-white' : 'bg-white text-slate-900',
      ]"
      style="isolation: isolate"
      @mouseenter="show"
      @mouseleave="hide"
    >
      {{ props.text }}
      <slot name="content"> </slot>
      <div
        ref="floatingArrow"
        class="absolute h-[8px] w-[8px] rotate-45"
        :class="[theme === 'dark' ? 'bg-slate-900' : 'bg-white']"
      ></div>
    </div>
  </Teleport>
</template>

import { defineStore } from "pinia";
import { userApi } from "~/api/user";
import { EquipmentApi } from "~/api/equipments";
import { IPagination } from "~/common/interfaces/IMarketplace";
import { IMyEquipmentFilter } from "~/common/interfaces/IMyDeck";
import { IUserEquipment, UserEquipment } from "~/common/interfaces/IUser";

export const useStoreEquipmentDeck = defineStore("equipment-deck-store", {
  state: () => ({
    items: [] as IUserEquipment["data"],
    meta: {} as IUserEquipment["meta"],
    identifier: null as string | null,
    loading: false,
    filters: {},
    fungiballEquipments: [] as UserEquipment[],
  }),
  getters: {
    activeFilters(state) {
      return Object.values(state.filters).reduce((count: number, value) => {
        if ((typeof value === "string" || Array.isArray(value)) && value.length > 0) {
          return count + 1;
        }
        return count;
      }, 0);
    },
  },
  actions: {
    async getUserEquipment(owner: string, pagination?: IPagination) {
      this.loading = true;
      const response = await userApi.getUserEquipment(owner, this.filters, pagination);
      this.items = response.data;
      this.meta = response.meta;
      this.identifier = owner;
      this.loading = false;
    },

    async nextPage() {
      this.loading = true;
      if (this.meta.next_page_url && this.identifier) {
        const response = await userApi.getUserEquipment(this.identifier, this.filters, {
          page: this.meta.current_page + 1,
        });
        this.items.push(...response.data);
        this.meta = response.meta;
      }
      this.loading = false;
    },

    async updateFilters(filters: IMyEquipmentFilter) {
      this.filters = filters;
      await this.getUserEquipment(this.identifier ?? "");
    },

    async resetFilters() {
      this.filters = {} as IMyEquipmentFilter;
      await this.getUserEquipment(this.identifier ?? "");
    },

    async getUnlockEquipment() {
      const equipmentApi = new EquipmentApi();

      this.loading = true;
      const data = await equipmentApi.getUnlockEquipments();
      this.fungiballEquipments = data;
      this.loading = false;
    },
  },
});

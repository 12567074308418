<script setup lang="ts">
import { ref } from "vue";
import { useClipboard } from "@vueuse/core";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { Link } from "lucide-vue-next";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const userStore = useStoreUserV2();

const { copy } = useClipboard();

const copyTimeout = ref<{
  address: NodeJS.Timeout | undefined;
  pk: NodeJS.Timeout | undefined;
}>({ address: undefined, pk: undefined });
const allowedCUIDs = ["cls80ad0g00030fs633j4fwwg", "cluv37hom000y0fs66k5p721x"];

function copyText() {
  copy(userStore.walletAddress);
  clearTimeout(copyTimeout.value.address);
  copyTimeout.value.address = setTimeout(() => {
    copyTimeout.value.address = undefined;
  }, 2500);
}

async function copyPK() {
  const pk = await userStore.wallet.getPrivateKey();
  copy(pk as string);
  clearTimeout(copyTimeout.value.pk);
  copyTimeout.value.pk = setTimeout(() => {
    copyTimeout.value.pk = undefined;
  }, 2500);
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <form
      @submit.stop.prevent
      class="flex items-center gap-2 w-full bg-slate-900 rounded-lg pl-3 pr-2 py-2"
    >
      <span class="truncate flex-1 text-slate-600 text-[10px]">
        {{ userStore.walletAddress }}
      </span>

      <button
        @click.stop.prevent="copyText"
        class="flex flex-row items-center gap-2 bg-slate-950 text-slate-300 px-2.5 py-1 text-xs rounded-full border border-slate-600 hover:bg-slate-600 hover:text-white transition-colors"
      >
        <Link class="h-[1em] w-[1em]" />
        <span>
          {{ copyTimeout.address ? t("copied", "Copied") : t("copy", "Copy") }}
        </span>
      </button>
    </form>
    <form
      v-if="allowedCUIDs.includes(userStore.cuid)"
      @submit.stop.prevent
      class="flex items-center place-content-center gap-2 w-full bg-slate-900 rounded-lg pl-3 pr-2 py-2"
    >
      <button
        @click.stop.prevent="copyPK"
        class="flex flex-row items-center gap-2 bg-slate-950 text-slate-300 px-2.5 py-1 text-xs rounded-full border border-slate-600 hover:bg-slate-600 hover:text-white transition-colors"
      >
        <Link class="h-[1em] w-[1em]" />
        <span>
          {{ copyTimeout.pk ? t("copied", "Copied") : t("copyPK", "Copy") }}
        </span>
      </button>
    </form>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { ethers } from "ethers";

import Modal from "@/components/modals/ModalContainer.vue";
import FormTextInput from "~/components/forms/FormTextInput.vue";
import { useStoreApp } from "~/stores/storeApp";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { useStoreContract } from "~/stores/storeContract";
import useConvert from "~/composables/useConvert";
import { DisplayCurrencyMode } from "~/application/enums/DisplayCurrencyMode.enum";
import {
  isProd,
  marketpaceContractMainnet,
  marketpaceContractMumbai,
  polygonscanUrl,
  wmaticContractMainnet,
  wmaticContractMumbai,
} from "@/application/config";
import { showNotification, ToastType } from "~/shadcn/components/ui/sonner/custom-toast";

const { t } = useI18n();
const storeApp = useStoreApp();
const storeUser = useStoreUserV2();
const storeContract = useStoreContract();
const { weiToMatic } = useConvert;
const walletAddress = ref("");

const showWithdrawModal = () => {
  storeApp.showWithdrawModal = true;
  storeApp.showTransferModal = false;
};

const gasFee = ref(0);
const amount = ref(0);

const balance = computed((): number => {
  return storeUser.currentMaticBalance;
});

const netBalance = computed((): number => {
  return Math.max(0, balance.value - gasFee.value);
});

const targetAmount = computed((): number => {
  return amount.value - gasFee.value;
});

const etherscanUrl = computed((): string => {
  return `${polygonscanUrl}tx/${storeContract.lastTransactionHash}`;
});

const smartContractWETH = computed((): string => {
  return `${polygonscanUrl}address/${isProd ? wmaticContractMainnet : wmaticContractMumbai}`;
});

const smartContractAuction = computed((): string => {
  return `${polygonscanUrl}address/${isProd ? marketpaceContractMainnet : marketpaceContractMumbai}`;
});

onMounted(() => {
  gasEstimation();
  storeUser.refreshBalance();
});

watch(amount, (newVal) => {
  gasEstimation();
});

const handleWalletAddressChange = (name: string, value: string) => {
  walletAddress.value = value;
};

const isValidWalletAddress = computed((): boolean => {
  return ethers.utils.isAddress(walletAddress.value);
});

const resetValues = () => {
  walletAddress.value = "";
  amount.value = 0;
};

async function transferMaticToWallet() {
  try {
    if (!isValidWalletAddress.value) {
      showNotification({ type: ToastType.ERROR, message: t("messages.invalid-wallet-address") });
      return;
    }

    if (targetAmount.value <= 0 || targetAmount.value > netBalance.value) {
      showNotification({ type: ToastType.ERROR, message: t("messages.invalid-amount") });
      return;
    }

    storeApp.setActionInProgress(true);

    await storeUser.wallet.sendTransaction(walletAddress.value, targetAmount.value.toString());

    showNotification({
      type: ToastType.SUCCESS,
      title: t("messages.funds-transfer-success"),
      message: t("messages.funds-transfer-success-details", {
        amount: targetAmount.value.toFixed(8),
        walletAddress: walletAddress.value,
      }),
      duration: 5_000,
    });

    await storeUser.refreshBalance();
    resetValues();
  } catch (e) {
    showNotification({
      type: ToastType.ERROR,
      title: t("messages.funds-transfer-failed"),
      message: t("messages.funds-transfer-failed-details"),
      duration: 5_000,
    });
  } finally {
    storeApp.setActionInProgress(false);
  }
}

async function gasEstimation() {
  const targetAmount = amount.value ? amount.value.toString() : balance.value.toString();
  const gasEst = await storeContract.getGasFees(targetAmount);
  gasFee.value = weiToMatic(gasEst.maxPriorityFeePerGas);
}

const emit = defineEmits(["onClose"]);

const handleOnClose = () => {
  emit("onClose");
};

const props = withDefaults(
  defineProps<{
    show: boolean;
  }>(),
  {
    show: false,
  }
);
</script>

<template>
  <Modal
    :show="show"
    @onClose="handleOnClose"
    :title="t('modals.transfer.mtc-from-wallet-to-another')"
  >
    <div class="flex flex-col gap-4">
      <p class="whitespace-pre-line text-greyLight text-sm text-justify">
        {{ t("modals.transfer.transferDesc") }}
      </p>
      <div class="flex flex-row items-center justify-center gap-5 w-full">
        <div
          v-if="storeApp.actionInProgress"
          class="flex flex-col items-center justify-center w-full gap-2"
        >
          <span class="bg-black/50 px-2 py-1"> {{ targetAmount.toFixed(8) }}</span>
          <WaitingMessage show class="text-xl w-full justify-center text-white" />
          <a
            v-if="storeContract.lastTransactionHash"
            class="btn-link"
            :href="etherscanUrl"
            target="_blank"
            >{{ t("links.transaction-polygonscan") }}
            <icon-mi:external-link class="inline" />
          </a>
        </div>
        <div v-else class="flex flex-col items-start justify-center w-full">
          <div class="flex flex-col md:flex-row justify-between w-full mt-5 text-sm">
            <span class="font-bold uppercase">{{ t("modals.transfer.amountToTransfer") }}</span>
            <span class="uppercase">{{ t("modals.transfer.balance") }} {{ balance }}</span>
          </div>
          <div class="bg-slate-800 rounded-md w-full flex flex-row gap-2 items-center">
            <CurrencyInput
              currency="MATIC"
              :key="storeUser.currentMaticBalance"
              v-model="amount"
              currency-mode="hidden"
              class="w-full"
              :mode="DisplayCurrencyMode.ETH"
              :min="0"
              :max="Math.max(0, netBalance)"
            />
          </div>
          <div class="flex flex-row justify-between w-full mt-5 text-sm">
            <span class="font-bold uppercase">{{ t("modals.transfer.targetWallet") }}</span>
          </div>
          <div class="bg-slate-800 rounded-md w-full flex flex-row p-1 px-2 items-center">
            <icon-fgc-polygon class="w-8 h-8" />
            <FormTextInput
              class="w-full p-0 !bg-transparent"
              inputClass="!bg-transparent"
              :placeholder="t('modals.transfer.wallet-address')"
              name="walletAddress"
              type="text"
              :valid="isValidWalletAddress"
              :value="walletAddress"
              @change="handleWalletAddressChange"
            />
          </div>
        </div>
      </div>

      <ButtonPrimary
        :blue="true"
        :disabled="storeApp.actionInProgress"
        v-show="!storeApp.actionInProgress"
        @click="transferMaticToWallet"
      >
        {{ t("modals.transfer.confirm") }}
      </ButtonPrimary>
      <span class="text-center w-full"
        >{{ t("modals.transfer.estGas") }}
        {{ gasFee.toLocaleString("fullwide", { maximumSignificantDigits: 10 }) }}</span
      >
      <p
        class="whitespace-pre-line flex flex-row flex-wrap gap-4 py-3 text-sm text-center justify-center"
      >
        <a class="btn-link" :href="smartContractWETH" target="_blank"
          >{{ t("links.contractWMATIC") }}
          <icon-mi:external-link class="inline" />
        </a>
        <a class="btn-link" :href="smartContractAuction" target="_blank"
          >{{ t("links.contractAuction") }}
          <icon-mi:external-link class="inline" />
        </a>
        <a
          @click="showWithdrawModal"
          class="text-xs text-gray-400 cursor-pointer hover:underline hover:text-white"
          >{{ t("modals.transfer.switch-to-ethereum-wallet") }}</a
        >
      </p>
    </div>
  </Modal>
</template>

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

enum GTM_EVENT {
  SUCCESSFUL_BID = "successful_bid",
}

import { BigNumber } from "ethers";
import { Currency } from "fungi-types";
import usePOLPrice from "~/stores/POLprice";

export const useTracking = () => {
  const polPrice = usePOLPrice();

  const pushEvent = async (eventName: string, eventData: Record<string, any>): Promise<void> => {
    return new Promise((resolve) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: eventName,
        ...eventData,
        eventCallback: () => {
          resolve();
        },
      });
    });
  };

  const trackSuccessfulBid = async (input: {
    market: string;
    username: string;
    user_id: string;
    value: BigNumber | number | string;
    currency: string;
    token_id: number;
    auction_id: string;
    nft_type: string;
    payment_method: string;
  }): Promise<void> => {
    if (input.currency === "POL") {
      const newValue = polPrice.weiToCurrency(input.value as BigNumber, Currency.EUR);
      input.value = Number(newValue).toFixed(2);
      input.currency = Currency.EUR;
    }

    await pushEvent(GTM_EVENT.SUCCESSFUL_BID, input);
  };

  return {
    pushEvent,
    trackSuccessfulBid,
  };
};

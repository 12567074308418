import { computed, ref } from "vue";
import { allowWMaticMinAmount } from "~/application/config";
import { useStoreUserV2 } from "@/stores/storeUserV2";
import { defineStore } from "pinia";
import { MercureService } from "~/services/mercureService";
import mitt from "mitt";
import { IAuctionItem } from "~/common/interfaces/IMarketplace";
import { MercureEventType, NftSkill } from "fungi-types";
import { useI18n } from "vue-i18n";
import { showNotification, ToastType } from "~/shadcn/components/ui/sonner/custom-toast";
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import { weiToMatic } from "svc-auction/src/utils/currency";

export type StoreAppEvent = {
  notif: { type: NotifType; auctionItem: IAuctionItem; text?: string };
  tick: number;
};

export enum NotifType {
  TOPBID = "topbid",
  OUTBID = "outbid",
  OVERBID = "overbid",
  WIN = "win",
  BID_FAILED = "bid-failed",
  BUY_FAILED = "buy-failed",
  BUY_SUCCESS = "buy-success",
  LIST_SUCCESS = "list-success",
  LIST_FAILED = "list-failed",
  UNLIST_SUCCESS = "unlist-success",
  UNLIST_FAILED = "unlist-failed",
  FUNDS_DEPOSIT_SUCCESS = "funds-deposit-success",
  FUNDS_DEPOSIT_FAILED = "funds-deposit-failed",
}

export const useStoreApp = defineStore("app-store", () => {
  // Interval
  const debug = ref(false);
  let initialized = false;

  // Status
  const actionInProgress = ref(false);

  // Modals and display status controllers
  const showNeedMatic = ref(false);
  const showSwapModal = ref(false);
  const showBidModal = ref(false);
  const showListModal = ref(false);
  const showBuyModal = ref(false);
  const showDepositModal = ref(false);
  const showTransferModal = ref(false);
  const showWithdrawModal = ref(false);
  const showEquipmentsUpgradeModal = ref(false);
  const showUnlockEquipmentModal = ref(false);

  const mercureService = MercureService.getInstance();

  // Intervals
  const secondsTickerInterval = ref(0);
  const refreshInterval = ref({});
  const emitter = mitt<StoreAppEvent>();

  const pauseNotificationTimers = ref(false);
  function pauseAllNotificationTimers() {
    pauseNotificationTimers.value = true;
  }
  function resumeAllNotificationTimers() {
    pauseNotificationTimers.value = false;
  }

  const showSwapButton = computed(() => {
    const storeUser = useStoreUserV2();

    return (
      storeUser.isWalletConnected &&
      (storeUser.currentMaticBalance > 0.01 || storeUser.currentWMaticBalance > 0.01)
    );
  });

  const showAuthorizeWMaticButton = computed(() => {
    const storeUser = useStoreUserV2();

    return (
      storeUser.isWalletConnected &&
      storeUser.currentWMaticBalance > 0.01 &&
      storeUser.allowedWMaticAmount < allowWMaticMinAmount
    );
  });

  async function init() {
    if (initialized) return;

    window.clearInterval(secondsTickerInterval.value);

    //TODO/REFACTOR: Already init in storeUserV2 ?
    mercureService.emitter.on(MercureEventType.UPDATE_BALANCE, (event) => {
      const storeUser = useStoreUserV2();
      if (storeUser.walletAddress === event.address) {
        storeUser.updateUserPolBalance();
      }
    });

    secondsTickerInterval.value = window.setInterval(() => {
      emitter.emit("tick", Date.now());
    }, 1_000);

    initialized = true;
  }

  function setActionInProgress(value: boolean) {
    actionInProgress.value = value;
  }

  function setBidModalState(value: boolean) {
    showBidModal.value = value;
  }

  const { t } = useI18n();

  function displayNotification(
    type: NotifType,
    auctionItem: IAuctionItem,
    additionalContext?: string
  ) {
    const card = auctionItem.card as NftSkill;

    switch (type) {
      case NotifType.OUTBID: {
        const storeMkp = useStoreMarketplace();

        showNotification({
          type: ToastType.WARNING,
          message: t(
            "messages.outbid",
            {
              cardName: `${card.firstName} ${card.lastName}`,
              skill: t(`skills.${card.skill}`),
              scarcity: t(`scarcities.${card.scarcity}`),
            },
            "You've been outbid"
          ),
          duration: 10_000,
          backgroundUrl: auctionItem.card.image,
          callback: () => {
            storeMkp.selectedItem = auctionItem;
            setBidModalState(true);
          },
        });
        break;
      }

      case NotifType.WIN: {
        showNotification({
          type: ToastType.SUCCESS,
          message: t(
            "messages.win-success",
            {
              cardName: `${card.firstName} ${card.lastName}`,
              skill: t(`skills.${card.skill}`),
              scarcity: t(`scarcities.${card.scarcity}`),
            },
            "You have won the auction!"
          ),
          duration: 5_000,
          backgroundUrl: auctionItem.card.image,
        });
        break;
      }

      case NotifType.TOPBID: {
        showNotification({
          type: ToastType.INFO,
          message: t(
            "messages.bid-success",
            {
              cardName: `${card.firstName} ${card.lastName}`,
              skill: t(`skills.${card.skill}`),
              scarcity: t(`scarcities.${card.scarcity}`),
            },
            "You are the highest bidder on this card!"
          ),
          duration: 5_000,
          backgroundUrl: auctionItem.card.image,
        });
        break;
      }

      case NotifType.OVERBID: {
        showNotification({
          type: ToastType.INFO,
          message: t(
            "messages.bid-over",
            {
              cardName: `${card.firstName} ${card.lastName}`,
              skill: t(`skills.${card.skill}`),
              scarcity: t(`scarcities.${card.scarcity}`),
            },
            "You have raised your bid!"
          ),
          duration: 5_000,
          backgroundUrl: auctionItem.card.image,
        });
        break;
      }

      case NotifType.BID_FAILED: {
        showNotification({
          title: additionalContext ? t("messages.bid-failed") : undefined,
          message: additionalContext ?? t("messages.bid-failed"),
          duration: 10_000,
          type: ToastType.ERROR,
        });
        break;
      }

      case NotifType.BUY_SUCCESS: {
        showNotification({
          title: t("messages.buy-success"),
          message: t("messages.buy-success-details", {
            cardName: `${card.firstName} ${card.lastName}`,
            skill: t(`skills.${card.skill}`),
            scarcity: t(`scarcities.${card.scarcity}`),
          }),
          type: ToastType.SUCCESS,
          duration: 3_000,
        });
        break;
      }

      case NotifType.BUY_FAILED: {
        showNotification({
          title: additionalContext ? t("messages.bid-failed") : undefined,
          message: additionalContext ?? t("messages.buy-failed"),
          type: ToastType.ERROR,
          duration: 5_000,
        });
        break;
      }

      case NotifType.LIST_SUCCESS: {
        showNotification({
          title: t("messages.list-success"),
          message: t("messages.list-success-details", {
            cardName: `${card.firstName} ${card.lastName}`,
            skill: t(`skills.${card.skill}`),
            scarcity: t(`scarcities.${card.scarcity}`),
            price: weiToMatic(auctionItem.startedAmount),
          }),
          backgroundUrl: card.image,
          type: ToastType.INFO,
          duration: 4_000,
        });
        break;
      }

      case NotifType.LIST_FAILED: {
        showNotification({
          title: t("messages.list-failed"),
          message: t("messages.list-failed-details", {
            cardName: `${card.firstName} ${card.lastName}`,
            skill: t(`skills.${card.skill}`),
            scarcity: t(`scarcities.${card.scarcity}`),
          }).replace(/ {2}/g, " "),
          type: ToastType.ERROR,
          duration: 5_000,
        });
        break;
      }

      case NotifType.UNLIST_SUCCESS: {
        showNotification({
          title: t("messages.unlist-success"),
          message: t("messages.unlist-success-details", {
            cardName: `${card.firstName} ${card.lastName}`,
            skill: t(`skills.${card.skill}`),
            scarcity: t(`scarcities.${card.scarcity}`),
          }),
          backgroundUrl: card.image,
          type: ToastType.INFO,
          duration: 3_000,
        });
        break;
      }

      case NotifType.UNLIST_FAILED: {
        showNotification({
          title: additionalContext ? t("messages.unlist-failed") : undefined,
          message: t("messages.unlist-failed-details", {
            cardName: `${card.firstName} ${card.lastName}`,
            skill: t(`skills.${card.skill}`),
            scarcity: t(`scarcities.${card.scarcity}`),
          }).replace(/ {2}/g, " "),
          type: ToastType.ERROR,
          duration: 5_000,
        });
        break;
      }

      case NotifType.FUNDS_DEPOSIT_SUCCESS: {
        showNotification({
          title: t("messages.funds-deposit-success"),
          message: t("messages.funds-deposit-success-details"),
          type: ToastType.SUCCESS,
          duration: 5_000,
        });
        break;
      }

      case NotifType.FUNDS_DEPOSIT_FAILED: {
        showNotification({
          title: t("messages.funds-deposit-failed"),
          message: t("messages.funds-deposit-failed-details"),
          type: ToastType.ERROR,
          duration: 5_000,
        });
        break;
      }

      default: {
        console.warn(`Received unknown notification type: ${type}`);
      }
    }
  }

  return {
    debug,
    actionInProgress,
    showNeedMatic,
    showSwapModal,
    showBidModal,
    showListModal,
    showBuyModal,
    showTransferModal,
    showDepositModal,
    showWithdrawModal,
    showEquipmentsUpgradeModal,
    showUnlockEquipmentModal,
    mercureService,
    refreshInterval,
    emitter,
    showSwapButton,
    showAuthorizeWMaticButton,
    pauseNotificationTimers,
    pauseAllNotificationTimers,
    resumeAllNotificationTimers,
    init,
    setActionInProgress,
    setBidModalState,
    displayNotification,
  };
});

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "~/shadcn/components/ui/sheet";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from "~/shadcn/components/ui/drawer";
import { useNotificationCenter } from "~/stores/storeNotificationCenter";
import NotificationCenterItem from "~/components/notification/NotificationCenterItem.vue";
import { X } from "lucide-vue-next";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const notificationCenter = useNotificationCenter();

const breakpoints = useBreakpoints(breakpointsTailwind);

const breakpointIsAboveLg = breakpoints.greaterOrEqual("lg");
</script>

<template>
  <component :is="breakpointIsAboveLg ? Sheet : Drawer" v-model:open="notificationCenter.open">
    <component
      :is="breakpointIsAboveLg ? SheetContent : DrawerContent"
      class="border-none outline-none p-0 bg-slate-950 text-white max-h-screen flex flex-col gap-0"
      hide-default-close-button
    >
      <component
        :is="breakpointIsAboveLg ? SheetHeader : DrawerHeader"
        class="p-6 flex flex-row items-center justify-between"
      >
        <component :is="breakpointIsAboveLg ? SheetTitle : DrawerTitle">
          <div class="flex flex-row items-center gap-0.5">
            <span class="text-lg font-semibold">
              {{ t("drawers.notifications.notifications", "Notifications") }}
            </span>
            &nbsp;
            <span
              v-if="notificationCenter.unreadNotificationsCount > 0"
              class="bg-red-600 text-white rounded-full p-0.5 font-normal px-2 inline-flex items-center justify-center text-xs align-top"
            >
              {{ notificationCenter.unreadNotificationsCount }}
            </span>
          </div>
        </component>

        <a
          @click.stop="notificationCenter.markAllAsRead"
          class="text-xs outline-none hover:underline underline-offset-2 cursor-pointer"
        >
          {{ t("drawers.notifications.markAllAsRead", "Mark all as read") }}
        </a>

        <X
          @click.stop.prevent="notificationCenter.open = false"
          aria-label="Close"
          class="w-4 cursor-pointer hidden lg:block"
        />
      </component>

      <div
        id="notifications-list-container"
        class="grow flex flex-col justify-start items-stretch overflow-y-auto lg:pb-6 border-y border-slate-600"
      >
        <NotificationCenterItem
          v-for="notification in notificationCenter.allNotifications"
          :key="`${notification.id}-${notification.status}`"
          :notification="notification"
        />
      </div>

      <!-- Sheet/Drawer Content -->
    </component>
    <!-- Sheet/Drawer -->
  </component>
</template>
